import { useMemo } from 'react'
import { LoadingDots } from '@components/ui'
import dynamic from 'next/dynamic'
import ShopCommonContext from './context/ShopCommonContext'

const Loading = () => (
  <div className="flex h-80 w-80 items-center justify-center p-3 text-center">
    <LoadingDots />
  </div>
)

const dynamicProps = {
  loading: Loading,
}

const DefaultPage = dynamic(() => import('templates/Pages/Default'), {
  ...dynamicProps,
})

const NotFound = dynamic(() => import('pages/404'), {
  ...dynamicProps,
})

const Home = dynamic(() => import('templates/Pages/Home'), {
  ...dynamicProps,
})

const Search = dynamic(() => import('templates/Pages/Search/Search'), {
  ...dynamicProps,
})

const Clean = dynamic(() => import('templates/Pages/Brands/Clean'), {
  ...dynamicProps,
})

const Security = dynamic(() => import('templates/Pages/Brands/Security'), {
  ...dynamicProps,
})

const EufyLocalSecurity = dynamic(
  () => import('templates/Pages/MacroEcology/security/security'),
  {
    ...dynamicProps,
  }
)

const Mach = dynamic(() => import('templates/Pages/Brands/Mach'), {
  ...dynamicProps,
})

const Referral = dynamic(() => import('templates/Pages/Brands/Referral'), {
  ...dynamicProps,
})

const BuildOwnSystem = dynamic(() => import('templates/Pages/BuildOwnSystem'), {
  ...dynamicProps,
})

const CommunityReferral = dynamic(
  () => import('templates/Pages/Brands/CommunityReferral'),
  {
    ...dynamicProps,
  }
)

const ExclusiveReferral = dynamic(
  () => import('templates/Pages/Brands/ExclusiveReferral'),
  {
    ...dynamicProps,
  }
)

const SolarFeature = dynamic(() => import('templates/Pages/Solar'), {
  ...dynamicProps,
})

const Livestream = dynamic(() => import('templates/Pages/Livestream'), {
  ...dynamicProps,
})

const Recall = dynamic(() => import('templates/Pages/Recall'), {
  ...dynamicProps,
})
const Deep = dynamic(() => import('templates/Pages/Deep/clean'), {
  ...dynamicProps,
})

const Credit = dynamic(() => import('templates/Pages/Credit'), {
  ...dynamicProps,
})
const PrimeCredit = dynamic(() => import('templates/Pages/PrimeCredit'), {
  ...dynamicProps,
})
const Solocam = dynamic(() => import('templates/Pages/Solocam'), {
  ...dynamicProps,
})

const DoorbellE340 = dynamic(
  () => import('templates/Pages/DualCamera/DoorbellE340'),
  {
    ...dynamicProps,
  }
)

const FloodlightcamE340 = dynamic(
  () => import('templates/Pages/DualCamera/FloodlightcamE340'),
  {
    ...dynamicProps,
  }
)

const IndoorCamS350 = dynamic(
  () => import('templates/Pages/DualCamera/IndoorCamS350'),
  {
    ...dynamicProps,
  }
)

const SolocamS340 = dynamic(
  () => import('templates/Pages/DualCamera/SolocamS340'),
  {
    ...dynamicProps,
  }
)

const CollectionPage = dynamic(() => import('templates/Pages/CollectionPage'), {
  ...dynamicProps,
})

const PartnerProgram = dynamic(() => import('templates/Pages/PartnerProgram'), {
  ...dynamicProps,
})

const BusinessSolutions = dynamic(
  () => import('templates/Pages/BusinessSolutions'),
  {
    ...dynamicProps,
  }
)
const CloudStorage = dynamic(() => import('templates/Pages/CloudStorage'), {
  ...dynamicProps,
})

const Appliance = dynamic(() => import('templates/Pages/Appliance/appliance'), {
  ...dynamicProps,
})

const SpecialBreastPump = dynamic(
  () => import('templates/Pages/SpecialBreastPump'),
  {
    ...dynamicProps,
  }
)
const PhoneSubscribe = dynamic(() => import('templates/Pages/PhoneSubscribe'), {
  ...dynamicProps,
})

const SecurityDailyScene = dynamic(
  () => import('templates/Pages/MacroEcology/security-daily-scene'),
  {
    ...dynamicProps,
  }
)

const SecurityFeatures = dynamic(
  () => import('templates/Pages/MacroEcology/security-features'),
  {
    ...dynamicProps,
  }
)

const MacroEcology = dynamic(
  () => import('templates/Pages/MacroEcology/security-hub/security-hub'),
  {
    ...dynamicProps,
  }
)

const EufyCam3 = dynamic(() => import('templates/Pages/EufyCam3'), {
  ...dynamicProps,
})

const EufyCam3Pro = dynamic(() => import('templates/Pages/EufyCam3Pro'), {
  ...dynamicProps,
})

const ApplianceX8Pro = dynamic(() => import('templates/Pages/ApplianceX8Pro'), {
  ...dynamicProps,
})

const ApplianceX9Pro = dynamic(() => import('templates/Pages/ApplianceX9Pro'), {
  ...dynamicProps,
})
const S1Page = dynamic(() => import('templates/Pages/S1ProClean'), {
  ...dynamicProps,
})

const SpringSale = dynamic(() => import('templates/Pages/SpringSale'), {
  ...dynamicProps,
})

const YouthDiscount = dynamic(() => import('templates/Pages/YouthDiscount'), {
  ...dynamicProps,
})

const SeniorDiscount = dynamic(() => import('templates/Pages/SeniorDiscount'), {
  ...dynamicProps,
})

const AboutUs = dynamic(() => import('templates/Pages/AboutUs'), {
  ...dynamicProps,
})

const ContactUs = dynamic(() => import('templates/Pages/ContactUs'), {
  ...dynamicProps,
})

const Faq = dynamic(() => import('templates/Pages/Faq'), {
  ...dynamicProps,
})

const AppliancesAppDownload = dynamic(
  () => import('templates/Pages/AppliancesAppDownload'),
  {
    ...dynamicProps,
  }
)

const PrivacyCommitment = dynamic(
  () => import('templates/Pages/PrivacyCommitment'),
  {
    ...dynamicProps,
  }
)

const Xseries = dynamic(() => import('templates/Pages/Xseries'), {
  ...dynamicProps,
})

const GoogleAssistant = dynamic(
  () => import('templates/Pages/GoogleAssistant'),
  {
    ...dynamicProps,
  }
)

const Affiliate = dynamic(() => import('templates/Pages/Affiliate'), {
  ...dynamicProps,
})

const AmazonAlexa = dynamic(() => import('templates/Pages/AmazonAlexa'), {
  ...dynamicProps,
})

const AlarmSystem = dynamic(() => import('templates/Pages/AlarmSystem'), {
  ...dynamicProps,
})
const S220 = dynamic(() => import('templates/Pages/S220'), {
  ...dynamicProps,
})

const S100 = dynamic(() => import('templates/Pages/S100'), {
  ...dynamicProps,
})

const BreastPumpWramUp = dynamic(() => import('templates/Pages/BreastPump'), {
  ...dynamicProps,
})
const Fanfest = dynamic(() => import('templates/Pages/Fanfest'), {
  ...dynamicProps,
})

const AfterShip = dynamic(() => import('templates/Pages/AfterShip'), {
  ...dynamicProps,
})

const Commitment = dynamic(() => import('templates/Pages/Commitment'), {
  ...dynamicProps,
})

const S1ProMoments = dynamic(() => import('templates/Pages/S1ProMoments'), {
  ...dynamicProps,
})
const RefurbishedProducts = dynamic(
  () => import('templates/Pages/RefurbishedProducts'),
  {
    ...dynamicProps,
  }
)

const PrimeDay = dynamic(() => import('templates/Pages/PrimeDay'), {
  ...dynamicProps,
})
const SolarPlus = dynamic(() => import('templates/Pages/SolarPlus'), {
  ...dynamicProps,
})
const TradeIn = dynamic(() => import('templates/Pages/TradeIn'), {
  ...dynamicProps,
})
const TradeInClean = dynamic(() => import('templates/Pages/TradeInClean'), {
  ...dynamicProps,
})

const AccessoriesSubscription = dynamic(
  () => import('templates/Pages/AccessoriesSubscription'),
  {
    ...dynamicProps,
  }
)
const HelpMeChoose = dynamic(() => import('templates/Pages/HelpMeChoose'), {
  ...dynamicProps,
})

const ApplianceUGC = dynamic(() => import('templates/Pages/ApplianceUGC'), {
  ...dynamicProps,
})

const LaborDay = dynamic(() => import('templates/Pages/LaborDay'), {
  ...dynamicProps,
})

const EufyMemberExclusiveDeals = dynamic(
  () => import('templates/Pages/EufyMemberExclusiveDeals'),
  {
    ...dynamicProps,
  }
)
const OrderTracker = dynamic(() => import('templates/Pages/OrderTracker'), {
  ...dynamicProps,
})

const SmartLighting = dynamic(() => import('templates/Pages/SmartLighting'), {
  ...dynamicProps,
})

const Halloween = dynamic(() => import('templates/Pages/Halloween'), {
  ...dynamicProps,
})

const FloorWashing = dynamic(() => import('templates/Pages/FloorWashing'), {
  ...dynamicProps,
})

const NewMemberExclusiveDeals = dynamic(
  () => import('templates/Pages/NewMemberExclusiveDeals'),
  {
    ...dynamicProps,
  }
)

const ThanksForJoining = dynamic(
  () => import('templates/Pages/ThanksForJoining'),
  {
    ...dynamicProps,
  }
)

const BlackFriday2024 = dynamic(
  () => import('templates/Pages/BlackFriday2024'),
  {
    ...dynamicProps,
  }
)

const NextDayShipping = dynamic(
  () => import('templates/Pages/NextDayShipping'),
  {
    ...dynamicProps,
  }
)

const ExpressShipping = dynamic(
  () => import('templates/Pages/ExpressShipping'),
  {
    ...dynamicProps,
  }
)

const ZipCodeTool = dynamic(() => import('templates/Pages/CodeTool'), {
  ...dynamicProps,
})

const Pages = (props) => {
  const { slug, metafields, type } = props
  const current = (metafields?.template || slug)?.trim()

  const targetTemp = (path, tempMap) => {
    const temp = tempMap.find((v) => {
      return v.rules && v.rules.some((fn) => fn(path))
    })
    // 兼容路由异常导致next层返回404数据
    if (type === '404') {
      return <NotFound {...props} />
    }
    return (
      temp?.templates || tempMap.find((v) => v.name === 'Default')?.templates
    )
  }

  const template = useMemo(
    () =>
      targetTemp(current, [
        {
          name: 'Default',
          templates: <DefaultPage {...props} />,
        },
        {
          name: 'collection-page',
          templates: <CollectionPage {...props} />,
          rules: [(current) => current === '/collection-page'],
        },
        {
          name: 'robot-vacuums',
          templates: <CollectionPage {...props} />,
          rules: [(current) => current === '/robot-vacuums'],
        },
        {
          name: 'Home',
          templates: <Home {...props} />,
          rules: [(current) => current === '/home'],
        },
        {
          name: 'HomePage',
          templates: <Home {...props} />,
          rules: [(current) => current === '/homepage'],
        },
        {
          name: 'search',
          templates: <Search {...props} />,
          rules: [(target) => target?.includes('search')],
        },
        {
          name: 'BuildOwnSystem',
          templates: <BuildOwnSystem {...props} />,
          rules: [
            (current) => current?.includes('build-your-own-security-system'),
          ],
        },
        {
          name: 'security-eufycam3',
          templates: <EufyCam3 {...props} />,
          rules: [(current) => current?.includes('security-eufycam3')],
        },
        {
          name: 'eufycam-pro',
          templates: <EufyCam3Pro {...props} />,
          rules: [(current) => current === '/eufycam-pro'],
        },
        {
          name: 'deepclean',
          templates: <Deep {...props} />,
          rules: [(current) => current?.includes('deepclean')],
        },
        {
          name: 'security-hub',
          templates: <MacroEcology {...props} />,
          rules: [(current) => current?.includes('security-hub')],
        },
        {
          name: 'SecurityFeatures',
          templates: <SecurityFeatures {...props} />,
          rules: [(current) => current?.includes('security-features')],
        },
        {
          name: 'spring-clean-sale',
          templates: <SpringSale {...props} />,
          rules: [(current) => current === 'spring-clean-sale'],
        },
        {
          name: 'eufy-clean-x-series',
          templates: <Xseries {...props} />,
          rules: [(current) => current?.includes('eufy-clean-x-series')],
        },
        {
          name: 'Clean',
          templates: <Clean {...props} />,
          rules: [(current) => current === '/clean'],
        },
        {
          name: 'Security',
          templates: <Security {...props} />,
          rules: [(current) => current === '/security'],
        },
        {
          name: 'Mach',
          templates: <Mach {...props} />,
          rules: [(current) => current?.includes('mach')],
        },
        {
          name: 'ReferralNew',
          templates: <Referral {...props} />,
          rules: [(current) => current?.includes('referral-new')],
        },
        {
          name: 'ReferralNew',
          templates: <Referral {...props} />,
          rules: [(current) => current?.includes('referral-new')],
        },
        {
          name: 'communityReferralProgram',
          templates: <CommunityReferral {...props} />,
          rules: [(current) => current?.includes('community-referral-program')],
        },
        {
          name: 'exclusiveCommunityOffer',
          templates: <ExclusiveReferral {...props} />,
          rules: [(current) => current?.includes('exclusive-community-offer')],
        },
        {
          name: 'livestream',
          templates: <Livestream {...props} />,
          rules: [(current) => current?.includes('livestream')],
        },
        {
          name: 'recall',
          templates: <Recall {...props} />,
          rules: [
            (current) => current?.includes('battery-replacement-program'),
          ],
        },
        {
          name: 'EufyCredit',
          templates: <PrimeCredit {...props} />,
          rules: [(temp) => temp === '/eufycredits'],
        },
        {
          name: 'PrimeCredit',
          templates: <PrimeCredit {...props} />,
          rules: [(temp) => temp === '/primecredits'],
        },
        {
          name: 'dualCamera',
          templates: <Solocam {...props} />,
          rules: [(current) => current?.includes('dual-camera')],
        },
        {
          name: 'video-doorbell-e340',
          templates: <DoorbellE340 {...props} />,
          rules: [(current) => current === '/video-doorbell-e340'],
        },
        {
          name: 'floodlight-cam-e340',
          templates: <FloodlightcamE340 {...props} />,
          rules: [(current) => current?.includes('floodlight-cam-e340')],
        },
        {
          name: 'indoorcam-s350',
          templates: <IndoorCamS350 {...props} />,
          rules: [(current) => current?.includes('indoorcam-s350')],
        },
        {
          name: 'solocam-s340',
          templates: <SolocamS340 {...props} />,
          rules: [(current) => current?.includes('solocam-s340')],
        },
        {
          name: 'video-doorbell',
          templates: <CollectionPage {...props} />,
          rules: [(current) => current === '/video-doorbell'],
        },
        {
          name: 'wall-light-camera',
          templates: <CollectionPage {...props} />,
          rules: [(current) => current === '/wall-light-camera'],
        },
        {
          name: 'wired-indoor-cam',
          templates: <CollectionPage {...props} />,
          rules: [(current) => current === '/wired-indoor-cam'],
        },
        {
          name: 'video-baby-monitor',
          templates: <CollectionPage {...props} />,
          rules: [(current) => current === '/video-baby-monitor'],
        },
        {
          name: 'baby',
          templates: <CollectionPage {...props} />,
          rules: [(current) => current === '/baby'],
        },
        {
          name: 'smart-sock',
          templates: <CollectionPage {...props} />,
          rules: [(current) => current === '/smart-sock'],
        },
        {
          name: 'security-s220-solocam',
          templates: <S220 {...props} />,
          rules: [(current) => current === '/security-s220-solocam'],
        },
        {
          name: 'solocam',
          templates: <CollectionPage {...props} />,
          rules: [(current) => current === '/solocam'],
        },
        {
          name: 'wired-camera',
          templates: <CollectionPage {...props} />,
          rules: [(current) => current === '/wired-camera'],
        },
        {
          name: 'eufycam',
          templates: <CollectionPage {...props} />,
          rules: [(current) => current === '/eufycam'],
        },
        {
          name: 'smart-lock',
          templates: <CollectionPage {...props} />,
          rules: [(current) => current === '/smart-lock'],
        },
        {
          name: 'battery-camera',
          templates: <CollectionPage {...props} />,
          rules: [(current) => current === '/battery-camera'],
        },
        {
          name: 'wired-outdoor-cam',
          templates: <CollectionPage {...props} />,
          rules: [(current) => current === '/wired-outdoor-cam'],
        },
        {
          name: 'video-smart-lock',
          templates: <CollectionPage {...props} />,
          rules: [(current) => current === '/video-smart-lock'],
        },
        {
          name: 'baby-monitor',
          templates: <CollectionPage {...props} />,
          rules: [(current) => current === '/baby-monitor'],
        },
        {
          name: 'floodlight-cam',
          templates: <CollectionPage {...props} />,
          rules: [(current) => current === '/floodlight-cam'],
        },
        {
          name: 'wall-night-light',
          templates: <CollectionPage {...props} />,
          rules: [(current) => current === '/wall-night-light'],
        },
        {
          name: 'indoor-security-camera',
          templates: <CollectionPage {...props} />,
          rules: [(current) => current === '/indoor-security-camera'],
        },
        {
          name: 'smart-floodlight',
          templates: <CollectionPage {...props} />,
          rules: [(current) => current === '/smart-floodlight'],
        },
        {
          name: 'partner-program',
          templates: <PartnerProgram {...props} />,
          rules: [(current) => current?.includes('partner-program')],
        },
        {
          name: 'eufy-smart-lock',
          templates: <CollectionPage {...props} />,
          rules: [(current) => current === '/eufy-smart-lock'],
        },
        {
          name: 'smart-scales',
          templates: <CollectionPage {...props} />,
          rules: [(current) => current === '/smart-scales'],
        },
        {
          name: 'smart-business-solutions',
          templates: <BusinessSolutions {...props} />,
          rules: [(current) => current === '/smart-business-solutions'],
        },
        {
          name: 'security-daily-scene',
          templates: <SecurityDailyScene {...props} />,
          rules: [(current) => current?.includes('security-daily-scene')],
        },
        {
          name: 'appliance-x10-omni',
          templates: <Appliance {...props} />,
          rules: [(current) => current?.includes('appliance-x10-omni')],
        },
        {
          name: 'wearable-breast-pump',
          templates: <SpecialBreastPump {...props} />,
          rules: [(current) => current?.includes('wearable-breast-pump')],
        },
        {
          name: 'phone-subscribe',
          templates: <PhoneSubscribe {...props} />,
          rules: [(current) => current?.includes('phone-subscribe')],
        },
        {
          name: 'eufy-local-security',
          templates: <EufyLocalSecurity {...props} />,
          rules: [(current) => current?.includes('eufy-local-security')],
        },
        {
          name: 'appliance-x8-pro',
          templates: <ApplianceX8Pro {...props} />,
          rules: [(current) => current?.includes('appliance-x8-pro')],
        },
        {
          name: 'appliance-x9-pro',
          templates: <ApplianceX9Pro {...props} />,
          rules: [(current) => current?.includes('appliance-x9-pro')],
        },
        {
          name: 'robot-vacuum-s1-pro',
          templates: <S1Page {...props} />,
          rules: [(current) => current?.includes('robot-vacuum-s1-pro')],
        },
        {
          name: 'eufy-sales',
          templates: <SpringSale {...props} />,
          rules: [(current) => current === 'eufy-sales'],
        },
        {
          name: 'spring-sale',
          templates: <SpringSale {...props} />,
          rules: [(current) => current === '/spring-sale'],
        },
        {
          name: 'security-sale',
          templates: <SpringSale {...props} />,
          rules: [(current) => current === 'security-sale'],
        },
        {
          name: 'spring-security-sale',
          templates: <SpringSale {...props} />,
          rules: [(current) => current === 'spring-security-sale'],
        },
        {
          name: 'eufy-security',
          templates: <SpringSale {...props} />,
          rules: [(current) => current === '/eufy-security'],
        },
        {
          name: 'eufy-hotdeal',
          templates: <SpringSale {...props} />,
          rules: [(current) => current === '/eufy-hotdeal'],
        },
        {
          name: 'security-sale',
          templates: <SpringSale {...props} />,
          rules: [(current) => current === '/security-sale'],
        },
        {
          name: 'security-deals',
          templates: <SpringSale {...props} />,
          rules: [(current) => current === '/security-deals'],
        },
        {
          name: 'security-hot-deal',
          templates: <SpringSale {...props} />,
          rules: [(current) => current === '/security-hot-deal'],
        },
        {
          name: 'hotdeal',
          templates: <SpringSale {...props} />,
          rules: [(current) => current === '/hotdeal'],
        },
        {
          name: 'appliances-hot-deal',
          templates: <SpringSale {...props} />,
          rules: [(current) => current === '/appliances-hot-deal'],
        },
        {
          name: 'appliance-hot-deal',
          templates: <SpringSale {...props} />,
          rules: [(current) => current === '/appliance-hot-deal'],
        },
        {
          name: 'affiliate-best-deals',
          templates: <SpringSale {...props} />,
          rules: [(current) => current === '/affiliate-best-deals'],
        },
        {
          name: 'eufy-bundle-deals',
          templates: <SpringSale {...props} />,
          rules: [(current) => current === '/eufy-bundle-deals'],
        },
        {
          name: 'flash-sale-lifemart',
          templates: <SpringSale {...props} />,
          rules: [(current) => current === '/flash-sale-lifemart'],
        },
        {
          name: 'flash-sale',
          templates: <SpringSale {...props} />,
          rules: [(current) => current === '/flash-sale'],
        },
        {
          name: 'eufy-appliances-hot-deal',
          templates: <SpringSale {...props} />,
          rules: [(current) => current === '/eufy-appliances-hot-deal'],
        },
        {
          name: 'youthdiscount',
          templates: <YouthDiscount {...props} />,
          rules: [(current) => current === '/youthdiscount'],
        },
        {
          name: 'seniordiscount',
          templates: <SeniorDiscount {...props} />,
          rules: [(current) => current === '/seniordiscount'],
        },
        {
          name: 'About Us',
          templates: <AboutUs {...props} />,
          rules: [(current) => current?.includes('about')],
        },
        {
          name: 'Contact Us',
          templates: <ContactUs {...props} />,
          rules: [(current) => current?.includes('contact')],
        },
        {
          name: 'Faq',
          templates: <Faq {...props} />,
          rules: [(current) => current?.includes('faq')],
        },
        {
          name: 'Appliances App Download',
          templates: <AppliancesAppDownload {...props} />,
          rules: [(current) => current?.includes('appliances-app-download')],
        },
        {
          name: 'Privacy Commitment',
          templates: <PrivacyCommitment {...props} />,
          rules: [(current) => current?.includes('privacy-commitment')],
        },
        {
          name: 'Google Assistant',
          templates: <GoogleAssistant {...props} />,
          rules: [(current) => current?.includes('google-assistant')],
        },
        {
          name: 'Affiliate',
          templates: <Affiliate {...props} />,
          rules: [(current) => current?.includes('affiliate')],
        },
        {
          name: 'AmazonAlexa',
          templates: <AmazonAlexa {...props} />,
          rules: [(current) => current?.includes('amazon-alexa')],
        },
        {
          name: 'AlarmSystem',
          templates: <AlarmSystem {...props} />,
          rules: [(current) => current?.includes('alarm-system')],
        },
        {
          name: 'S100',
          templates: <S100 {...props} />,
          rules: [
            (current) =>
              current?.includes('eufy-security-s100-wired-wall-light-cam'),
          ],
        },
        {
          name: 'breast-pump',
          templates: <BreastPumpWramUp {...props} />,
          rules: [(current) => current?.includes('breast-pump')],
        },
        {
          name: 'Fanfest',
          templates: <Fanfest {...props} />,
          rules: [(current) => current === '/fanfest'],
        },
        {
          name: 'cloud-storage',
          templates: <CloudStorage {...props} />,
          rules: [(current) => current === '/cloud-storage'],
        },
        {
          name: 'S1ProMoments',
          templates: <S1ProMoments {...props} />,
          rules: [(current) => current === '/s1-pro-moments'],
        },
        {
          name: 'father-day',
          templates: <SpringSale {...props} />,
          rules: [(current) => current === '/father-day'],
        },
        {
          name: 'aftership',
          templates: <AfterShip {...props} />,
          rules: [(current) => current === '/aftership'],
        },
        {
          name: 'commitment',
          templates: <Commitment {...props} />,
          rules: [(current) => current === '/commitment-to-protection'],
        },
        {
          name: 'refurbished-products',
          templates: <RefurbishedProducts {...props} />,
          rules: [(current) => current === '/refurbished-products'],
        },
        {
          name: 'solar',
          templates: <SpringSale {...props} />,
          rules: [(current) => current === '/solar'],
        },
        {
          name: 'prime-day',
          templates: <PrimeDay {...props} />,
          rules: [(current) => current === '/prime-day'],
        },
        {
          name: 'solarplus',
          templates: <SolarPlus {...props} />,
          rules: [(current) => current === '/solarplus'],
        },
        {
          name: 'appliance-trade-in',
          templates: <TradeIn {...props} />,
          rules: [(current) => current === '/appliance-trade-in'],
        },
        {
          name: 'how-to-choose-a-robot-vacuum',
          templates: <TradeInClean {...props} />,
          rules: [(current) => current === '/how-to-choose-a-robot-vacuum'],
        },
        {
          name: 'accessories-subscription',
          templates: <AccessoriesSubscription {...props} />,
          rules: [(current) => current === '/accessories-subscription'],
        },
        {
          name: 'ApplianceUGC',
          templates: <ApplianceUGC {...props} />,
          rules: [(current) => current === '/eufyclean-prizes'],
        },
        {
          name: 'labor-day',
          templates: <LaborDay {...props} />,
          rules: [(current) => current === '/labor-day'],
        },
        {
          name: 'eufy-member-exclusive-deals',
          templates: <EufyMemberExclusiveDeals {...props} />,
          rules: [(current) => current === '/eufy-member-exclusive-deals'],
        },
        {
          name: 'appliances-let-me-choose',
          templates: <HelpMeChoose {...props} />,
          rules: [(current) => current === '/appliances-let-me-choose'],
        },
        {
          name: 'order-tracker',
          templates: <OrderTracker {...props} />,
          rules: [(current) => current === '/order-tracker'],
        },
        {
          name: 'smart-lighting',
          templates: <SmartLighting {...props} />,
          rules: [(current) => current === '/smart-lighting'],
        },
        {
          name: 'halloween',
          templates: <Halloween {...props} />,
          rules: [(current) => current === '/halloween'],
        },
        {
          name: '',
          templates: <FloorWashing {...props} />,
          rules: [(current) => current === '/floor-washing-robot-vacuum'],
        },
        {
          name: 'new-member-exclusive-deals',
          templates: <NewMemberExclusiveDeals {...props} />,
          rules: [(current) => current === '/new-member-exclusive-deals'],
        },
        {
          name: 'thanks-for-joining-eufy',
          templates: <ThanksForJoining {...props} />,
          rules: [(current) => current === '/thanks-for-joining-eufy'],
        },
        {
          name: 'black-friday-2024',
          templates: <BlackFriday2024 {...props} />,
          rules: [(current) => current === '/black-friday-2024'],
        },
        {
          name: 'next-day-shipping',
          templates: <NextDayShipping {...props} />,
          rules: [(current) => current === '/next-day-shipping'],
        },
        {
          name: 'express-shipping',
          templates: <ExpressShipping {...props} />,
          rules: [(current) => current === '/express-shipping'],
        },
        {
          name: 'zipcode-tool',
          templates: <ZipCodeTool {...props} />,
          rules: [(current) => current === '/zipcode-tool'],
        },
      ]),
    [current, props]
  )

  return (
    <>
      <ShopCommonContext.Provider value={props.metafields.shopCommon}>
        {template}
      </ShopCommonContext.Provider>
    </>
  )
}

export default Pages
